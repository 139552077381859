import Vue from "vue";
import vueRouter from "vue-router";

Vue.use(vueRouter)

const routes = [
    {
        path: '/',
        // redirect: '',
        name: 'home',
        component: () => import('@/page/home/index'),
    },
    {
        path: '/scanCodePage',
        name: 'scanCodePage',
        component: () => import('@/page/scanCodePage/index'),
    },
    // {
    //     path: '/',
    //     component: () => import('@/page/home'),
    //     children: [
    //         {
    //             path: '/home',
    //             name: 'home',
    //             // component: () => import('@/page/home-page/index'),
    //             // meta: { title: '首页' }
    //         },
    //     ]
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/page/login/index')
    }
]

const router = new vueRouter({
    routes
})

// 全局守卫：前置守卫（在路由跳转之间进行判断）
// router.beforeEach((to, form, next) => {
//     // console.log('to-----', to, 'from-----', form)
//     next()
// })

export default router