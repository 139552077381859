/***
 * c_name: cookie名
 * value: 值
 * expire: 天数
 * 
 * **** escape(): 可对字符串进行编码，不会对 ASCII 字母和数字进行编码，也不会对下面这些 ASCII 标点符号进行编码： * @ - _ + . /
 *      unescape(): 可对编码的字符串进行解码
 */
 export function setCookie(c_name, value, expire) {
    var date = new Date();
    date.setTime(date.getTime() + (expire * 24 * 60 * 60 * 1000));
    document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString()
    // console.log(document.cookie)
}
    
export function getCookie(c_name) {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            
            let c_end = document.cookie.indexOf(";", c_start);

            if (c_end == -1) c_end = document.cookie.length;
            
            return unescape(document.cookie.substring(c_start, c_end))
        }
    }
    return ""
}