import axios from 'axios'
import Qs from 'qs'
import router from '../router'
import { Message } from 'element-ui'
import { env } from './index'

// 创建axios实例
const axiosInstance = axios.create({
  baseURL: env,
  // baseURL: process.env.BASE_API,
  withCredentials: true,  //是否允许跨域
  timeout: 15000 // 请求超时时间
})

// 添加一个请求拦截器
window.once = 0
axiosInstance.interceptors.request.use(
  function (config) {
    // form-data传参
    config.data = Qs.stringify(config.data);
    config.headers = {
      'Content-Type':'application/x-www-form-urlencoded'  // 默认form
    }
    // let token = localStorage.getItem('oaToken');
    // let token = Vue.prototype.$getCookie('oaToken');
    // token ? config.headers.token = token : ''
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加一个响应拦截器
axiosInstance.interceptors.response.use(
  function (response) {
    // 正常返回数据
    if(response.status === 200) {
      return response.data;
    } else {
      Message.error(response.data.resultMsg)
      return Promise.reject(new Error(response.data.resultMsg));
    }
  },
  function (error) {
    let response = error.response;
    if (response.status === 401) {
        // token过期，去登录
        window.once === 0 ? Message.error(response.data.resultMsg) : ''
        router.push({
            name: 'login'
        })
        // 报错只提示一次
        ++window.once
    }
    return Promise.reject(error);
  }
);

// export default axiosInstance

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
 export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axiosInstance.get(url, {
      params: params,
      responseType: "blob", // 响应类型, 将响应数据转换为二进制数据
    })
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err)
    })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axiosInstance.post(url, data)
      .then(response => {
        resolve(response);
      },err => {
        reject(err)
      })
  })
}


/**
 * 【 async 和 await  //使用两种语法结合，是为了使我们的异步代码，更像同步的代码 】
  
  // async 函数会返回一个promise，并且promise对象的结果由 async 函数执行的返回值决定
  async function main() {
    try {
      let result = await post('http://xxx', {})  //await 后面接一个会return new promise的函数并执行它，返回的是成功的值
      console.log('**', result)

    } catch (error) {
      console.log(error)
    }
  }
  
  // 调用
  main();
 *
 */