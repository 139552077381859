/*
包含n个接口请求函数的模块
函数的返回值: promise对象
 */

// export const env = 'http://192.168.0.123:8082/'
// export const wenv = 'ws://192.168.0.123:8082/'

// 测试
// export const env = 'http://192.168.0.100:8083/'
// export const wenv = 'ws://192.168.0.100:8083/'

//生产
export const env = 'https://sp.zjsuntree.com.cn/'
export const wenv = 'wss://sp.zjsuntree.com.cn/'