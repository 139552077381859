import Vue from 'vue'
import App from './App.vue'

import router from './router'

import { post, get } from './api/request'
import { setCookie, getCookie } from './utils/cookie'

import 'amfe-flexible'  // 移动端自适应，主要是将 1rem 设为 viewWidth/10

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false  // 阻止启动生产消息
// 定义全局变量
Vue.prototype.$post = post
Vue.prototype.$get = get

Vue.prototype.$setCookie = setCookie
Vue.prototype.$getCookie = getCookie

Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
